import { ReplyIcon } from '@heroicons/react/outline';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth';
import axios from 'axios';
import Loading from '../../components/Loading';
import { FaUnlock } from 'react-icons/fa';
import ShareResultComponent from '../../components/ShareResult';
import { useModal } from '../../contexts/ModalContext';
import Footer from '../../components/Footer';
import ReactMarkdown from 'react-markdown';




const CoinsTermMap = {
    "Observer": "Observation Focus",
    "Decider": "Decision Focus",
    "Gatherer": "Gathering",
    "Organizer": "Organizing",
    "Self": "Individuality",
    "Tribe": "Collaboration",
    "Sensing": "Concrete",
    "Intuition": "Abstract",
    "Thinking": "Mind",
    "Feeling": "Heart",
    "Energy": "Energy Focus",
    "Info": "Info Focus",
    "Masculine Sensing": "Assertive Concreteness",
    "Feminine Sensing": "Receptive Concreteness",
    "Masculine Tribe": "Assertive Collaboration",
    "Feminine Tribe": "Receptive Collaboration",
    "Masculine Organizing": "Assertive Organization",
    "Feminine Organizing": "Receptive Organization",
    "Masculine Thinking": "Assertive Mind",
    "Feminine Thinking": "Receptive Mind",
    "Blast": "Fall Behavior",
    "Consume": "Spring Behavior",
    "Sleep": "Winter Behavior",
    "Play": "Summer Behavior",
    "Se": "Broad Concrete Function",
    "Si": "Deep Concrete Function",
    "Te": "Broad Mind Function",
    "Ti": "Deep Mind Function",
}


function reorganizeCoinsByApiName(data) {
    const result = {};
    data.forEach(item => {
        const apiName = item.attributes.api_name;
        result[apiName] = item.attributes;
    });
    return result;
}
const remainingTraits =
    [

        {
            "title": "Energy",
            "description": "You are more concerned with the vibe. You are aware if it is good or bad, and care more about the unspoken frequency and connections and relationships with yourself and others than the trivialities of some particularl information. Because of your awareness of this field, you are likely to be able to work more hours, but at a relaxing pace with good breaks and distractions, and maintain a good relationship with your work, rather than take it so seriously with an ability to only work in shorter sprints.",
            "image": {
                "access": "public",
                "path": "/vault/r5M4nz7i/v8UngOVUS62bmc4x_hxXvHECM40/n5mBQg../energy.webp",
                "name": "energy.webp",
                "type": "image",
                "size": 52598,
                "mime": "image/webp",
                "meta": {
                    "width": 1024,
                    "height": 1024
                },
                "url": "https://xbvh-ofc9-nsmy.n7c.xano.io/vault/r5M4nz7i/v8UngOVUS62bmc4x_hxXvHECM40/n5mBQg../energy.webp"
            },
            "negative_title": "Energy",
            "positive_title": "Info",
            "score": 0,
            "abs_score": 0
        },
        {
            "title": "Feminine Sensing",
            "description": "You are gentle with the physical word, and experience a trippy abstract world. Drugs and alcohol may have stronger affects on your motor skills and normalization of physical reality, but your abstract world of learnings and knowledge is much more stable. A single data point might not fit your pattern, but you will still stand by the pattern. You may have an easier time getting lost or being late, but you also accidentally break things and hurt other people less often. Romantically, you may be drawn to people who are more physically tough. If you also have Feminine Tribe, you likely have a strong sense of smell and taste while being fairly balance with visual, auditory, and kinesthetic learning. If you have Masculine Tribe, however, you likely have a strong preference for, and awareness of, your sense of sight. People with this disposition are disproprtionaly interior designins, videographers, illustrators, and graphic artists.",
            "image": {
                "access": "public",
                "path": "/vault/r5M4nz7i/WIeqjznNMeOOh3QxodtOzWkNPG4/3rZ3wg../f_sensing.webp",
                "name": "f_sensing.webp",
                "type": "image",
                "size": 34764,
                "mime": "image/webp",
                "meta": {
                    "width": 1024,
                    "height": 1024
                },
                "url": "https://xbvh-ofc9-nsmy.n7c.xano.io/vault/r5M4nz7i/WIeqjznNMeOOh3QxodtOzWkNPG4/3rZ3wg../f_sensing.webp"
            },
            "negative_title": "Masculine Sensing",
            "positive_title": "Feminine Sensing",
            "score": 0,
            "abs_score": 0
        },
        {
            "title": "Feminine Tribe",
            "description": "You are more gentle and careful with the people around you. You try not to offend or hurt them, often waiting to bring something up if it seems necessary, and then in a polite way. Rudeness, conflicts, and inappropriate aggressiveness with people make you feel uncomfortable and are not typical of you. It is because you actually have a secure, immoveable masculine inner world identity that you feel safe to be moved in the outside extroverted world with others when it comes to logic (Te/Fi Axis) or emotions and tastes (Fe/Ti Axis).",
            "image": {
                "access": "public",
                "path": "/vault/r5M4nz7i/j3meuEZIL0DP6oK0AC7ogu2GqtI/Q2qgfg../f_tribe.webp",
                "name": "f_tribe.webp",
                "type": "image",
                "size": 53724,
                "mime": "image/webp",
                "meta": {
                    "width": 1024,
                    "height": 1024
                },
                "url": "https://xbvh-ofc9-nsmy.n7c.xano.io/vault/r5M4nz7i/j3meuEZIL0DP6oK0AC7ogu2GqtI/Q2qgfg../f_tribe.webp"
            },
            "negative_title": "Masculine Tribe",
            "positive_title": "Feminine Tribe",
            "score": 0,
            "abs_score": 0
        },
        {
            "title": "Masculine Organizing",
            "description": "You feel obligated to stick to your own way of organizing the physical world with tightiness and rules (Si/Ne Axis) or the abstract world with uncomprimising conviction in the patterns you detect (Se/Ni Axis). You feel responsible to use your own plan and are more likely to strive to maintain the current way and improve it before trying to totally change it, especially if you are an Organizer type. You are usually on top of your schedule in life and love to-do lists and crossing out tasks from them. You are reliable, efficient, usually accurate, and attentive.",
            "image": {
                "access": "public",
                "path": "/vault/r5M4nz7i/KBJONnbC5gf0Q1DCq7Z_yipURrI/2O4C-Q../m_organizing.webp",
                "name": "m_organizing.webp",
                "type": "image",
                "size": 156010,
                "mime": "image/webp",
                "meta": {
                    "width": 1024,
                    "height": 1024
                },
                "url": "https://xbvh-ofc9-nsmy.n7c.xano.io/vault/r5M4nz7i/KBJONnbC5gf0Q1DCq7Z_yipURrI/2O4C-Q../m_organizing.webp"
            },
            "negative_title": "Masculine Organizing",
            "positive_title": "Feminine Organizing",
            "score": 0,
            "abs_score": 0
        },
        {
            "title": "Masculine Thinking",
            "description": "With Masculine Thinking, your own rules and logic overwrite external rules and logic. This can be seein in how you might walk accross a street, despite a red light, because it doesn't make sense to you to wait. Especially if you are a Thinking type, you will be very stubborn when it comes to logical conclusions and decisions, but you would subsequently have a more feminine emotional world in which you are not controlling or stubborn on emotional issues.",
            "image": {
                "access": "public",
                "path": "/vault/r5M4nz7i/TgpLuc3NsYE-EmF5pjBUVH9SWcw/jHbz3w../m_thinking.webp",
                "name": "m_thinking.webp",
                "type": "image",
                "size": 115028,
                "mime": "image/webp",
                "meta": {
                    "width": 1024,
                    "height": 1024
                },
                "url": "https://xbvh-ofc9-nsmy.n7c.xano.io/vault/r5M4nz7i/TgpLuc3NsYE-EmF5pjBUVH9SWcw/jHbz3w../m_thinking.webp"
            },
            "negative_title": "Masculine Thinking",
            "positive_title": "Feminine Thinking",
            "score": 0,
            "abs_score": 0
        },
        {
            "title": "Consume",
            "description": "Consume is one of the behaviors that work with information. This behavior encourages you to consume information and resources from the world and the people around you. People with a high Consume are constantly interested and consume new information in large volumes.You have to Consume over Blast, it is mean you are more comfortable receiving information and resources, listening, and learning than sharing, telling, or teaching.",
            "image": {
                "access": "public",
                "path": "/vault/r5M4nz7i/fX-7EQWoYAhlonpXScr0WUF7no0/-o1E6g../consume.webp",
                "name": "consume.webp",
                "type": "image",
                "size": 142866,
                "mime": "image/webp",
                "meta": {
                    "width": 1024,
                    "height": 1024
                },
                "url": "https://xbvh-ofc9-nsmy.n7c.xano.io/vault/r5M4nz7i/fX-7EQWoYAhlonpXScr0WUF7no0/-o1E6g../consume.webp"
            },
            "negative_title": "Blast",
            "positive_title": "Consume",
            "score": 0,
            "abs_score": 0
        },
        {
            "title": "Play",
            "description": "The Play behavior is about learning and exploring with other people to find shared solutions in an interactive way. You think better out loud or feel more energized working in a team than planning things solo in isolation. When a problem occurs or you need information, you do not hesitate to dive in with others. If your opposite Sleep behavior is your weakest, then you likely have trouble constantly being on the go and doing things without pausing to rest and relax. It can be good to take time to Play less and reflect more to learn from your past, recall people/information, or plan for the future. You may also have trouble resting enough or noticing when you are getting sick or injured.",
            "image": {
                "access": "public",
                "path": "/vault/r5M4nz7i/eem3ST8CXwEg0D7tBnVvvK_78oo/VFYPnQ../play.webp",
                "name": "play.webp",
                "type": "image",
                "size": 85722,
                "mime": "image/webp",
                "meta": {
                    "width": 1024,
                    "height": 1024
                },
                "url": "https://xbvh-ofc9-nsmy.n7c.xano.io/vault/r5M4nz7i/eem3ST8CXwEg0D7tBnVvvK_78oo/VFYPnQ../play.webp"
            },
            "negative_title": "Sleep",
            "positive_title": "Play",
            "score": 0,
            "abs_score": 0
        },
        {
            "title": "Se",
            "description": "This type of Sensing is responsible for grounded data gathering often showing up in physical activity, real-time tactical interaction, sports, etc. People with strong Se are naturally more physically developed, with a comfort taking risks. They usually are impatient to finish things and are excited by motion, action, and nature. This function helps us to survive in dangerous situations, and helps us to make quick decisions. To be able to do it, this function loves to collect and processes a lot of physical evidence, small details, and factual data from outside. People with strong Se (first or second) do not often take the time to plan ahead in detail, being more likely to have a general goal/direction then solve problems when they come.",
            "image": {
                "access": "public",
                "path": "/vault/r5M4nz7i/eCOm7sQ9wI9SgIqsHVP6LMXKjBQ/v_coqg../se.webp",
                "name": "se.webp",
                "type": "image",
                "size": 113364,
                "mime": "image/webp",
                "meta": {
                    "width": 1024,
                    "height": 1024
                },
                "url": "https://xbvh-ofc9-nsmy.n7c.xano.io/vault/r5M4nz7i/eCOm7sQ9wI9SgIqsHVP6LMXKjBQ/v_coqg../se.webp"
            },
            "negative_title": "Se",
            "positive_title": "Si",
            "score": 0,
            "abs_score": 0
        },
        {
            "title": "Ti",
            "description": "With this kind of Ti preference, you make decisions primarily according to your own personal logic. This sort of thinking is especially helpful with technical activities. You care less about what works for others, and more about understanding how and why something works. Even your own identity and likes are formed based on whatever reasons make sense to you. Be careful to not get too stuck in your own head and way of doing things, especially if you are a Decider. ",
            "image": {
                "access": "public",
                "path": "/vault/r5M4nz7i/4kO2Jp0N5svKD5H_kr4T78oNznQ/KclYFA../ti.webp",
                "name": "ti.webp",
                "type": "image",
                "size": 161578,
                "mime": "image/webp",
                "meta": {
                    "width": 1024,
                    "height": 1024
                },
                "url": "https://xbvh-ofc9-nsmy.n7c.xano.io/vault/r5M4nz7i/4kO2Jp0N5svKD5H_kr4T78oNznQ/KclYFA../ti.webp"
            },
            "negative_title": "Te",
            "positive_title": "Ti",
            "score": 0,
            "abs_score": 0
        }
    ]

function mapPercentageToState(percentage) {
    let name, BreakpointFill;
    if (percentage < 5) {
        name = "Unclear";
        BreakpointFill = 5;
    } else if (percentage >= 5 && percentage <= 14) {
        name = "Somewhat likely";
        BreakpointFill = 30;
    } else if (percentage > 14 && percentage <= 25) {
        name = "Likely";
        BreakpointFill = 60;
    } else if (percentage > 25) {
        name = "Very likely";
        BreakpointFill = 90;
    }

    return { name, BreakpointFill };
}

function replaceSpacesWithHyphens(str) {
    return str.replace(/\s/g, '-');
}

const prescriptionData = {
    "S": {
        "name": "Winter",
        "description": "A season for introspection and conservation of energy, as external conditions become less conducive to activity. To thrive, prioritize activities such as resting, reflecting, and engaging in introspective practices, allowing for the preservation of mental and emotional resources and the resolution of complex challenges."
    },
    "B": {
        "name": "Fall",
        "description": "A period of productivity and implementation, characterized by reaping the rewards of earlier efforts before the onset of winter. Maximize benefits by applying existing knowledge and skills in productive endeavors, taking action to initiate projects and diligently work towards achieving goals."
    },
    "C": {
        "name": "Spring",
        "description": "A season for exploration and learning, where the world undergoes renewal. To benefit, engage in activities such as reading, researching, and observing to acquire new knowledge and update your perspectives in alignment with the unfolding cycle."
    },
    "P": {
        "name": "Summer",
        "description": "A time of vibrant social interaction and practical application of insights amid favorable weather conditions. Embrace opportunities for engagement and play with others and the environment, such as conducting interviews and collaborating on projects, to deepen understanding and integrate learning."
    }
}

let globalPaymentURL = ""

function extractFunction(text) {
    const parts = text.split('-');
    if (parts.length > 1) {
        const functionPart = parts[1].split('/')[0];
        const secondFunctionPart = parts[1].split('/')[1].split('-')[0];
        return `${functionPart}/${secondFunctionPart}`;
    }
    // Return the original text if it doesn't match the expected format
    return text;
}

function transformString(input) {
    // Use a regular expression to match two alphabetical parts (e.g., "Te/Se") separated by a slash.
    // This pattern assumes each part consists of at least one character (change {1,} to a specific
    // number if you want to match parts of a certain length).
    // It captures the two parts around the slash for replacement.
    const pattern = /([A-Za-z]+)\/([A-Za-z]+)/;
    console.log(input)

    // Replace the matched pattern with the two captured groups concatenated together.
    let result = input?.replace(pattern, (match, p1, p2) => `${p1}${p2}`);

    return result;
}


const TraitBar = ({ trait, onHover, selectedHover, hoveredCoin, onCoinHover, index, is_paid }) => {
    const { name, BreakpointFill } = mapPercentageToState(trait.abs_score)
    return (
        <>
            {!is_paid && index > 4 ?
                <div className="grid grid-cols-1 gap-1 items-center justify-between border border-[#493A65] bg-[#493A65] bg-opacity-20 hover:bg-opacity-70 py-4 px-2 rounded-lg cursor-pointer">
                    <div className="flex items-center justify-between px-4">
                        <span onMouseEnter={() => onCoinHover(trait.negative_title)} className={`text-sm w-full cursor-pointer ${hoveredCoin === trait.negative_title ? 'text-[#7940CF] scale-110 transition delay-150 ease-in-out' : 'text-gray-600'}`}>{CoinsTermMap[trait.negative_title]}</span>
                        <button onClick={() => window.open(globalPaymentURL?.url, '_blank')} className="text-gray-600 text-xs flex text-center align-middle hover:scale-105 transition ease-in-out bg-[#7940CF] p-2 rounded-md cursor-pointer"><FaUnlock className='text-[#eee] m-auto'></FaUnlock><p>Unlock Result</p> </button>
                        <span onMouseEnter={() => onCoinHover(trait.positive_title)} className={`text-sm text-end w-full cursor-pointer ${hoveredCoin === trait.positive_title ? 'text-[#7940CF] scale-110 transition delay-150 ease-in-out' : 'text-gray-600'}`}>{CoinsTermMap[trait.positive_title]}</span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                        <div className={`grid grid-cols-2 justify-start  `}>
                            {trait.score < 0 ?
                                <div className={` ${trait.score < 0 ? 'rotate-180' : ''}`}>
                                    <div className="col-span-1  text-[10px]  rotate-180 font-medium text-blue-100 text-center p-0.5 h-3 leading-none rounded-full rounded-r-none border-r-2" style={{ width: `0%` }} ></div>
                                    <div className='col-span-1'></div>
                                </div>
                                :
                                <>
                                    <div className='col-span-1'></div>
                                    <div className="col-span-1 text-[10px] font-medium text-blue-100 text-center p-0.5 h-3 leading-none rounded-full rounded-l-none border-l-2" style={{ width: `0%` }}></div>
                                </>
                            }
                        </div>
                    </div>

                </div>
                :
                <>
                    {selectedHover === trait.title ?
                        <div onMouseEnter={() => onHover(trait)} data-tooltip-target="tooltip-no-arrow" className={`  relative group grid grid-cols-1 gap-1 scale-105 items-center justify-between border border-[#493A65] bg-[#493A65] bg-opacity-70 py-4 px-2 rounded-lg cursor-pointer`}>
                            <div className="flex items-center justify-between px-4 cursor-pointer">
                                <span onMouseEnter={() => onCoinHover(trait.negative_title)} className={`text-sm w-full cursor-pointer ${hoveredCoin === trait.negative_title ? 'text-[#7940CF] scale-110 transition delay-150 ease-in-out' : 'text-gray-600'}`}>
                                    {CoinsTermMap[trait.negative_title]}
                                </span>
                                <span onMouseEnter={() => onCoinHover(trait.positive_title)} className={`text-sm text-end w-full cursor-pointer ${hoveredCoin === trait.positive_title ? 'text-[#7940CF] scale-110 transition delay-150 ease-in-out' : 'text-gray-600'}`}>
                                    {CoinsTermMap[trait.positive_title]}
                                </span>
                            </div>

                            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                                <div className={`grid grid-cols-2 justify-start cursor-pointer `}>
                                    {trait.score < 0 ?
                                        <div className={` ${trait.score < 0 ? 'rotate-180' : ''}`}>
                                            <div className="col-span-1 bg-[#773FCF] text-[10px]  rotate-180 font-medium text-blue-100 text-center p-0.5 h-3 leading-none rounded-full rounded-r-none border-r-2 whitespace-nowrap" style={{ width: `${BreakpointFill}%` }}>{name}</div>
                                            <div className='col-span-1'></div>
                                        </div>
                                        :
                                        <>
                                            <div className={`col-span-1`}></div>
                                            <div className="col-span-1 bg-[#773FCF] text-[10px] font-medium text-blue-100 text-center p-0.5 h-3 leading-none rounded-full rounded-l-none border-l-2 whitespace-nowrap" style={{ width: `${BreakpointFill}%` }}>{name}</div>
                                        </>
                                    }
                                </div>
                            </div>
                            {/* <div className="absolute hidden group-hover:block md:group-hover:hidden bg-[#773FCF] text-white text-sm py-2 px-4 rounded z-10" style={{ bottom: "100%" }}>
                                <DetailsSection data={trait} />
                            </div> */}
                            <div className="relative group">

                            </div>
                        </div> :
                        <div onMouseEnter={() => onHover(trait)} className="grid grid-cols-1 gap-1 items-center justify-between border border-[#493A65] bg-[#493A65] bg-opacity-20 hover:bg-opacity-70 py-4 px-2 rounded-lg cursor-pointer">
                            <div className="flex items-center justify-between px-4">
                                <span onMouseEnter={() => onCoinHover(trait.negative_title)} className="text-gray-600 text-sm w-full ">{CoinsTermMap[trait.negative_title]}</span>
                                <span onMouseEnter={() => onCoinHover(trait.positive_title)} className="text-gray-600 text-sm w-full text-end right-0 float-end">{CoinsTermMap[trait.positive_title]}</span>
                            </div>
                            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                                <div className={`grid grid-cols-2 justify-start  `}>
                                    {trait.score < 0 ?
                                        <div className={` ${trait.score < 0 ? 'rotate-180' : ''}`}>
                                            <div className="col-span-1 bg-[#773FCF] text-[10px]  rotate-180 font-medium text-blue-100 text-center p-0.5 h-3 leading-none rounded-full rounded-r-none border-r-2 whitespace-nowrap" style={{ width: `${BreakpointFill}%` }}>{name}</div>
                                            <div className='col-span-1'></div>
                                        </div>
                                        :
                                        <>
                                            <div className='col-span-1'></div>
                                            <div className="col-span-1 bg-[#773FCF] text-[10px] font-medium text-blue-100 text-center p-0.5 h-3 leading-none rounded-full rounded-l-none border-l-2 whitespace-nowrap" style={{ width: `${BreakpointFill}%` }}>{name}</div>
                                        </>
                                    }
                                </div>
                            </div>

                        </div>
                    }
                </>
            }
        </>
    );
}
const ResultCard = ({ test, result, is_paid }) => {
    const [resultDetail, setResultDetail] = useState(null);
    const [paymentUrl, setPaymentUrl] = useState('')
    const [prescriptionDataLive, setPrescriptionDataLive] = useState({})
    const [alternativeList, setAlternativeList] = useState([])
    const [alternativeLoaded, setAlternativeLoaded] = useState(false)
    const { showModal } = useModal();
    const [testDetail, setTestDetail] = useState({});
    const [testDataLoaded, setTestDataLoaded] = useState(false)

    function extractPrescription(text) {
        const match = text.match(/\(([A-Z])\)/);
        return match ? match[1] : null;
    }
    const prescriptionLetter = extractPrescription(result)

    const handleNavigate = (result_name, mbti_type) => {
        console.log(result_name, mbti_type)
        let transformedMbti = replaceSpacesWithHyphens(mbti_type)
        const cmsIdentifier = is_paid ? extractFunction(result) : result
        window.location.href = `/personality-types/${result_name}-${transformedMbti}/summary`;
    };

    const fetchPersonalityTypeDetails = async () => {
        const token = "6b1875cbfb2e50bbe3805dbb0385a8b14e707bdbf2325674ddaad125239e7d3401f8aa2cca4094a3ffa38f6f790d510ada111a5f03626ebe6661ddbf95456582d5fa9795ee4c2958fb72a8cf3187897b219cd2551eddf83e2232de063f0a95c4ebad38bd5245e3a18e986a939db189a728793103eba8c2fe75128c921d7085d3";
        const cmsIdentifier = is_paid ? extractFunction(result) : result
        try {
            const response = await axios.get(`https://cms.typescience.ai/api/personality-types?filters[unique_name][$eq]=${cmsIdentifier}&populate=*`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setResultDetail(response)
        } catch (error) {
            console.error('Error fetching personality types:', error);
        }
    };

    const fetchPaymentURL = async () => {
        try {
            const response = await axios.post(`https://xbvh-ofc9-nsmy.n7c.xano.io/api:zhMAliV-:v1/session?share_id=${test.share_id}`); // Replace with your actual API endpoint
            setPaymentUrl(response.data);
            globalPaymentURL = response.data
        } catch (error) {
            console.error('Error fetching test data:', error);
        }
    }

    const fetchAlternativeData = async () => {
        const token = "6b1875cbfb2e50bbe3805dbb0385a8b14e707bdbf2325674ddaad125239e7d3401f8aa2cca4094a3ffa38f6f790d510ada111a5f03626ebe6661ddbf95456582d5fa9795ee4c2958fb72a8cf3187897b219cd2551eddf83e2232de063f0a95c4ebad38bd5245e3a18e986a939db189a728793103eba8c2fe75128c921d7085d3";
        try {
            const responseMain = await axios.post(
                `https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF/getAlternativeTypes`,
                {
                    "test_id": test.id
                }
            );
            const alternativeComputed = []
            for (var i = 0; i < responseMain?.data?.length; i++) {
                const cmsIdentifier = extractFunction(responseMain?.data[i]?.predictedType)
                try {
                    let response = await axios.get(`https://cms.typescience.ai/api/personality-types?filters[unique_name][$eq]=${cmsIdentifier}&populate=*`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    response.data.percentageChange = responseMain?.data[i]?.percentageChange
                    alternativeComputed.push(response)
                } catch (error) {
                    console.error('Error fetching personality types:', error);
                }
            }
            setAlternativeList(alternativeComputed);
            setAlternativeLoaded(true)
        } catch (error) {
            setAlternativeLoaded(true)
            console.error('Error fetching test data:', error);
        }
    }

    const fetchPrescriptionData = async () => {
        const token = "6b1875cbfb2e50bbe3805dbb0385a8b14e707bdbf2325674ddaad125239e7d3401f8aa2cca4094a3ffa38f6f790d510ada111a5f03626ebe6661ddbf95456582d5fa9795ee4c2958fb72a8cf3187897b219cd2551eddf83e2232de063f0a95c4ebad38bd5245e3a18e986a939db189a728793103eba8c2fe75128c921d7085d3";
        try {
            const response = await axios.get(`https://cms.typescience.ai/api/prescriptions?filters[identifier_letter][$eq]=${prescriptionLetter}&populate=*`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setPrescriptionDataLive(response)
        } catch (error) {
            console.error('Error fetching personality types:', error);
        }
    }
    const handleLoginButtonClick = () => {
        showModal(test?.share_id);
    };

    const fetchTest = async () => {
        try {
            const response = await axios.get(`https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF:v1/test/${data?.id}`);
            setTestDetail(response.data);
            setTestDataLoaded(true);
        } catch (error) {
            console.error('There was an error fetching the data:', error);
        }
    };


    useEffect(() => {
        fetchPrescriptionData()
        fetchPersonalityTypeDetails();
        fetchPaymentURL();
        fetchAlternativeData();
        fetchTest()
    }, []);

    return (
        <div className="col-span-12 xs:col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-5 mt-4">
            <div onClick={() => handleNavigate(resultDetail?.data?.data[0]?.attributes?.name, resultDetail?.data?.data[0]?.attributes?.description)}
                className=' border border-[#493A65] bg-[#493A65] bg-opacity-20 rounded-lg p-6 mb-4 '>
                <div className="grid grid-cols-1 text-center md:text-start lg:text-start xl:text-start md:grid-cols-12 items-center mb-4 gap-6">
                    <img src={"https://cms.typescience.ai" + resultDetail?.data?.data[0]?.attributes?.image?.data?.attributes?.url} alt={result} className="md:col-span-4 mx-auto border-none aspect-[0.9]" />
                    <div className='md:col-span-8'>
                        <h2 className="text-sm text-gray-500 font-bold">Your Result</h2>
                        <p className='font-grifter text-lg'>{resultDetail?.data?.data[0]?.attributes?.name}</p>
                        <p className='text-xs text-balance md:text-pretty'>{resultDetail?.data?.data[0]?.attributes?.introduction}</p>
                        <p>{resultDetail?.data?.data[0]?.attributes?.name+"-"+resultDetail?.data?.data[0]?.attributes?.description}</p>
                        <button
                            onClick={() => handleNavigate(resultDetail?.data?.data[0]?.attributes?.name, resultDetail?.data?.data[0]?.attributes?.description)}
                            className="hover:scale-105 transition ease-out delay-150 bg-opacity-15 border text-xs mx-auto md:w-1/2 border-[#7940CF] text-white bg-[#7940CF]  px-6 py-2 rounded-full mt-4">See More Details</button>
                    </div>

                </div>
            </div>
            <div className=' cursor-pointer border border-[#493A65] bg-[#493A65] bg-opacity-20 rounded-lg p-6 mb-4'>
                <div className="flex justify-between items-center mb-4">
                    <div>
                        <h2 className="text-sm text-gray-500 font-bold">Alternative Archetypes</h2>
                        <p className='text-xs'>These are alternative types to consider, in order of likeliness. Explore the Collaborative Insights panel below to enhance the accuracy of your results.</p>
                    </div>
                </div>
                <div>
                    {alternativeList.length > 0 ? (
                        <div className='flex overflow-x-auto space-x-4 p-4 text-center'>
                            {alternativeList?.map((alternate, index) => (
                                <div onClick={() => handleNavigate(alternate?.data?.data[0]?.attributes?.name, alternate?.data?.data[0]?.attributes?.description)} className='hover:scale-105 transition delay-150 ease-in-out' key={index}>
                                    <img
                                        src={"https://cms.typescience.ai" + (alternate?.data?.data[0]?.attributes?.image?.data?.attributes?.url || '')}
                                        alt={alternate?.data?.data[0]?.attributes?.name || 'Alternative'}
                                        className="md:col-span-4 mx-auto border-none aspect-[0.9] w-32 h-32"
                                    />
                                    <p className='font-grifter text-lg'>
                                        {(alternate?.data?.data[0]?.attributes?.name || '')}
                                    </p>
                                    <p className='text-xs text-balance md:text-pretty'>
                                        {alternate?.data?.data[0]?.attributes?.introduction || ''}
                                    </p>

                                </div>
                            ))}
                        </div>
                    ) : (
                        !alternativeLoaded ?
                            <center><Loading /></center> : <center><p className='text-2xs'>Your results conclusively show that you are the {resultDetail?.data?.data[0]?.attributes?.name} type</p></center>
                    )}
                </div>

            </div>
            {is_paid ?
                <div className=' cursor-pointer border border-[#493A65] bg-[#493A65] bg-opacity-20 rounded-lg p-6 mb-4'>
                    <div className="flex justify-between items-center mb-4">
                        <div>
                            <h2 className="text-sm text-gray-500 font-bold">Prescription</h2>
                            <p className='text-xs'>An area of life where you can enhance your well-being through specific actions and behaviors.</p>
                        </div>
                    </div>
                    <div className='flex flex-wrap gap-2 my-2'>
                        <img src={"https://cms.typescience.ai" + prescriptionDataLive?.data?.data[0]?.attributes?.image?.data?.attributes?.url} alt={result} className="border-none h-12 w-12 aspect-[1]" />
                        <p className='text-lg font-grifter my-auto'>{prescriptionData[prescriptionLetter].name}</p>
                    </div>
                    <p className='text-xs'>{prescriptionData[prescriptionLetter].description}</p>
                </div> :
                <div className='relative cursor-pointer border border-[#493A65] bg-[#493A65]  rounded-lg p-6 mb-4'>
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center rounded-lg">
                        {/* Lock Icon, replace with your icon component or SVG */}
                        <div className="text-4xl mt-8 lock-icon">
                            {/* Replace "🔒" with your lock icon component, e.g., <FaLock /> */}
                            🔒
                        </div>
                    </div>
                    <div className="flex justify-between items-center mb-4">
                        <div>
                            <h2 className="text-sm text-gray-500 font-bold">Prescription</h2>
                            <p className='text-xs'>An area of life where you can enhance your well-being through specific actions and behaviors.</p>
                        </div>
                    </div>
                    <div className='blur-sm'>
                        <p className='text-sm font-grifter'>An area of life where you can enhance your well-being through specific actions and behaviors.</p>
                        <p className='text-xs'>An area of life where you can enhance your well-being through specific actions and behaviors.</p>
                        <p className='text-xs'>An area of life where you can enhance your well-being through specific actions and behaviors.</p>
                    </div>
                </div>
            }

            {!is_paid && paymentUrl !== "" ? <button
                onClick={() => window.open(paymentUrl.url, '_blank')}
                className="border mx-auto w-full border-[#7940CF] text-white bg-[#7940CF]  px-6 py-2 rounded-full mt-4">Unlock Full Result</button> : <></>}
            {paymentUrl === "" && !is_paid ?
                <center>{testDetail?.taker === undefined ? <button onClick={handleLoginButtonClick} className=" cursor-pointer align-middle self-center hover:scale-105 mx-4 rounded-full bg-[#7035de] hover:bg-opacity-80 text-white py-2 px-4 text-xs font-bold md:py-3 md:px-8 md:text-md">Unlock Full Result</button> : ""}</center>
                : ""}

            <div className='hidden xl:flex '>
                {resultDetail ? <ShareResultComponent withSend={true} withPublicLink={true} test={test} personlaity_name={resultDetail?.data?.data[0]?.attributes?.name} personality_mbti={resultDetail?.data?.data[0]?.attributes?.description}></ShareResultComponent> : ""}
            </div>
        </div>
    );
};

const TraitsSection = ({ test, traits, onHover, selectedHover, hoveredCoin, onCoinHover, is_paid, result }) => {

    const [traitsModified, setTraitsModified] = useState([])
    const [paymentUrl, setPaymentUrl] = useState('')

    function appendList(originalList, listToAppend) {
        const traitsCompleted = originalList.concat(listToAppend);
        return traitsCompleted;
    }
    const fetchPaymentURL = async () => {
        try {
            const response = await axios.post(`https://xbvh-ofc9-nsmy.n7c.xano.io/api:zhMAliV-:v1/session?share_id=${test.share_id}`); // Replace with your actual API endpoint
            setPaymentUrl(response.data);
            globalPaymentURL = response.data

        } catch (error) {
            console.error('Error fetching test data:', error);
        }
    }
    useEffect(() => {
        let traitsComplete;
        if (!is_paid) {
            traitsComplete = appendList(traits, remainingTraits)
        }
        else {
            traitsComplete = traits
        }
        console.log(traitsModified)
        setTraitsModified(traitsComplete)
        fetchPaymentURL();

    }, []);
    return (
        <div className='col-span-12 xs:col-span-12 sm:col-span-12 md:col-span-1 xl:col-span-4 rounded-lg md:scroll-m-48 text-white'>
            <div className="border border-[#493A65] bg-[#493A65] bg-opacity-20 rounded-lg p-6 ">
                <h3 className="text-lg font-semibold mb-4">Your Traits</h3>
                <p className='text-xs my-5'>Explore your personality test results to understand more about your personality type and core traits</p>
                <div className="mb-4 grid grid-cols-1 gap-4">
                    {traitsModified?.map((trait, index) => (
                        <TraitBar onHover={(trait) => onHover(trait)} hoveredCoin={hoveredCoin} onCoinHover={onCoinHover} className="col-span-1" key={trait.name} trait={trait} selectedHover={selectedHover} index={index} is_paid={is_paid} />
                    ))}
                </div>
            </div>
        </div>
    );
};
const pCustom = ({ children, ...props }) => {
    return <p className="my-4" {...props}>{children}</p>
}

const DetailsSection = ({ data, cmsData, showImg, is_paid, test }) => {
    const [resultDetail, setResultDetail] = useState(null);


    const fetchPersonalityTypeDetails = async () => {
        const token = "6b1875cbfb2e50bbe3805dbb0385a8b14e707bdbf2325674ddaad125239e7d3401f8aa2cca4094a3ffa38f6f790d510ada111a5f03626ebe6661ddbf95456582d5fa9795ee4c2958fb72a8cf3187897b219cd2551eddf83e2232de063f0a95c4ebad38bd5245e3a18e986a939db189a728793103eba8c2fe75128c921d7085d3";
        const cmsIdentifier = is_paid ? extractFunction(test?.result) : test?.result
        try {
            const response = await axios.get(`https://cms.typescience.ai/api/personality-types?filters[unique_name][$eq]=${cmsIdentifier}&populate=*`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setResultDetail(response)
        } catch (error) {
            console.error('Error fetching personality types:', error);
        }
    };


    useEffect(() => {
        fetchPersonalityTypeDetails();
    }, []);

    return (
        <div className='relative xs:col-span-12 sm:col-span-12 md:col-span-1 xl:col-span-3 mb-10'>

            <div className=" fixed mr-24 border border-[#493A65] bg-[#493A65] bg-opacity-20 rounded-lg p-6">
                <>
                    <h3 className="text-4xl text-center font-grifter mb-4">{cmsData?.name}</h3>
                    {showImg ? <img className='transition ease-in-out delay-150 rounded-xl' src={"https://cms.typescience.ai" + cmsData?.image?.data?.attributes?.url} loading=""></img> : null}
                    <ReactMarkdown className='text-center text-xs my-5 text-zinc-300' components={{ p: pCustom }}>{cmsData?.description_ht}</ReactMarkdown>
                </>

            </div>
        </div>
    );
};
const SaveUnlockButton = ({ data, handleLoginButtonClick }) => {
    const [testDetail, setTestDetail] = useState({});
    const [testDataLoaded, setTestDataLoaded] = useState(false)

    const fetchTest = async () => {
        try {
            const response = await axios.get(`https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF:v1/test/${data?.id}`);
            setTestDetail(response.data);
            setTestDataLoaded(true);
        } catch (error) {
            console.error('There was an error fetching the data:', error);
        }
    };
    useEffect(() => {
        fetchTest();
    }, []);
    return (
        <>{testDetail?.taker === undefined ? <button onClick={handleLoginButtonClick} className=" right-0 float-right hover:scale-105 mx-4 rounded-full bg-[#7035de] hover:bg-opacity-80 text-white py-2 px-4 text-xs font-bold md:py-3 md:px-8 md:text-md">Save Test</button> : ""}</>
    );
};


export default function Dashboard({ withBack = true, fromPublic = false, resultsShareID = null }) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const testID = queryParams.get('id');
    const [testData, setTestData] = useState({})
    const [coinsData, setCoinsData] = useState({})
    const [coinsDataLoaded, setCoinsDataLoaded] = useState(false)
    const [testDataLoaded, setTestDataLoaded] = useState(false)
    const { isUserLoggedIn, logout, user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [hoveredTrait, setHoveredTrait] = useState({});
    const [hoveredCoin, sethoveredCoin] = useState("");
    const [showImage, setShowImage] = useState(false);
    const { showModal } = useModal();

    const handleMouseEnter = (trait => {
        setHoveredTrait(trait);
    });

    const onCoinHover = (coin => {
        sethoveredCoin(coin);
        setShowImage(true)
    });

    const handleLoginButtonClick = () => {
        showModal(resultsShareID);
    };

    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            try {
                let response;
                if (resultsShareID) {
                    response = await axios.get(`https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF:v1/share/${resultsShareID}`);
                }
                else {
                    response = await axios.get(`https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF:v1/test/${testID}`);

                }
                setTestData(response.data);
                setTestDataLoaded(true);
                setHoveredTrait(response?.data?.display[0])
                setIsLoading(false)
            } catch (error) {
                console.error('Error fetching test data:', error);
                setIsLoading(false)
            }
        };
        const fetchCoinData = async () => {
            try {
                const token = "6b1875cbfb2e50bbe3805dbb0385a8b14e707bdbf2325674ddaad125239e7d3401f8aa2cca4094a3ffa38f6f790d510ada111a5f03626ebe6661ddbf95456582d5fa9795ee4c2958fb72a8cf3187897b219cd2551eddf83e2232de063f0a95c4ebad38bd5245e3a18e986a939db189a728793103eba8c2fe75128c921d7085d3";
                const response = await axios.get(`https://cms.typescience.ai/api/coins?populate=*&pagination[pageSize]=32`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                console.log(response.data.data)
                let reorganizedCoins = reorganizeCoinsByApiName(response.data.data)
                console.log(reorganizedCoins)
                setCoinsData(reorganizedCoins);
                setCoinsDataLoaded(true);

            } catch (error) {
                console.error('Error fetching test data:', error);
                setIsLoading(false)
            }
        };
        fetchData();
        fetchCoinData();
    }, [isUserLoggedIn]);

    const traits = {
        Introverted: 90,
        Intuitive: 80,
        Thinking: 80,
        Decider: 50,
        Prospecting: 85,
        Turbulent: 70,
        Attitude: 70,
    };
    return (
        <div className="md:pl-32 py-2 bg-[#080421] !min-h-screen ">
            <div className="w-4/5 mx-auto text-white " >
                <div className="  text-white">
                    <div className='md:my-10 mb-5 grid grid-cols-12 gap-x-0 flex-initial'>
                        {withBack ? <div className="col-span-2 md:col-span-1">
                            <Link to={"/dashboard"} className="border-1 border-[#7940CF] rounded-full" ><ReplyIcon className='w-8 h-8 text-[#7940CF]'></ReplyIcon></Link>
                        </div> : ""}
                        <p className='col-span-4 md:col-span-2 self-center font-grifter'>Result Details </p>
                        <div className='col-span-1 md:col-span-1'>
                            {isLoading ?
                                <Loading></Loading>
                                :
                                null
                            }
                        </div>
                        {/* <div className='col-span-7 md:col-span-9'>
                            {fromPublic && testDataLoaded ? <SaveUnlockButton data={testData} handleLoginButtonClick={handleLoginButtonClick}></SaveUnlockButton> : ""}
                        </div> */}
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-12 xl:gap-4 space-y-4 space-x-4 !min-h-screen">

                        {testDataLoaded ? (<>
                            <ResultCard test={testData} result={testData.result} is_paid={testData.is_paid} />
                            <TraitsSection test={testData} hoveredCoin={hoveredCoin} onCoinHover={onCoinHover} result={testData.result} traits={testData.display || []} onHover={handleMouseEnter} selectedHover={hoveredTrait?.title} is_paid={testData.is_paid} />
                            <DetailsSection test={testData} data={hoveredTrait} cmsData={coinsData[hoveredCoin] || { description_ht: "Hover over the coins to see details" }} showImg={showImage} is_paid={testData.is_paid} />
                        </>) : ""}
                    </div>
                </div>


            </div>
        </div>
    )
}