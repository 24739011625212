import { useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../components/Loading';
import { ShareIcon, PencilIcon, XIcon } from '@heroicons/react/outline';
import EditTestModal from '../../components/EditTestModal';
import ShareTestModal from '../../components/ShareTestModal';
import { FaPaperPlane, FaPen, FaShare } from 'react-icons/fa';
import { ShimmerButton } from "react-shimmer-effects";

function extractFunction(text) {
    const parts = text.split('-');
    if (parts.length > 1) {
        const functionPart = parts[1].split('/')[0];
        const secondFunctionPart = parts[1].split('/')[1].split('-')[0];
        return `${functionPart}/${secondFunctionPart}`;
    }
    return text;
}

function ResultText({ result }) {
    const [text, setText] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [textReady, setTextReady] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const token = "6b1875cbfb2e50bbe3805dbb0385a8b14e707bdbf2325674ddaad125239e7d3401f8aa2cca4094a3ffa38f6f790d510ada111a5f03626ebe6661ddbf95456582d5fa9795ee4c2958fb72a8cf3187897b219cd2551eddf83e2232de063f0a95c4ebad38bd5245e3a18e986a939db189a728793103eba8c2fe75128c921d7085d3";
            const cmsIdentifier = extractFunction(result)
            try {
                const response = await axios.get(`https://cms.typescience.ai/api/personality-types?filters[unique_name][$eq]=${cmsIdentifier}&populate=*`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setTextReady(true)
                setImageUrl(response?.data?.data[0]?.attributes?.image?.data?.attributes?.url)
                setText(response?.data?.data[0]?.attributes?.name)
            }
            catch (error) {
                console.error('Error fetching personality types:', error);
                return "";
            }
        };
        if (result) {
            fetchData();
        }
    }, [result]);  // Re-run this effect if `queryId` changes

    return (
        <div className='flex text-center items-center gap-1'><object className="avatar w-8" data={"https://cms.typescience.ai" + imageUrl} type="image/jpeg"><img className="w-8" src={"https://cms.typescience.ai" + imageUrl} loading="lazy"></img></object>{textReady ? text : ""}</div>
    );
}

const DashboardHome = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [testData, setTestData] = useState({
        tests: [],
        shared_tests: [],
        merged_tests: [],
    });
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedTest, setSelectedTest] = useState({});
    const [isEditTestModalOpen, setEditTestModalOpen] = useState(false);
    const [isShareTestModalOpen, setShareTestModalOpen] = useState(false);
    const [isSendTestModalOpen, setSendTestModalOpen] = useState(false);
    const [copiedTest, setCopiedTest] = useState();
    const [showTooltip, setShowTooltip] = useState(false);

    const { isUserLoggedIn, logout, user } = useAuth();

    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(`${process.env.REACT_APP_PUBLIC_URL}/share?id=${text}`);
            setShowTooltip(true);
            setTimeout(() => setShowTooltip(false), 2000);
            setCopiedTest(text);
        } catch (err) {
            setCopiedTest("");
        }
    };

    const handleLockClick = async (test) => {
        setIsLoading(true)
        console.log(test)
        try {
            const response = await axios.post(`https://xbvh-ofc9-nsmy.n7c.xano.io/api:zhMAliV-:v1/session?share_id=${test.share_id}`); // Replace with your actual API endpoint
            console.log(response?.data?.url)
            window.open(response?.data?.url)
            setIsLoading(false)

        } catch (error) {
            console.error('Error fetching test data:', error);
            setIsLoading(false)
        }

    }


    const handleEditTest = (test) => {
        console.log(test)
        setSelectedTest(test)
        setEditTestModalOpen(true)
    };
    const handleUpdateTest = async (data, updatedName) => {
        setIsLoading(true)
        await axios.put(`https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF/test/${data.id}`, { name: updatedName })
            .then(response => {
                console.log('Response:', response.data);
                fetchData()
                setIsLoading(false)
                setEditTestModalOpen(false)
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false)
                setEditTestModalOpen(false)
            });
    }

    const handleCheckboxChange = (testId, isChecked) => {
        if (isChecked) {
            setSelectedRows([...selectedRows, testId]);
        } else {
            setSelectedRows(selectedRows.filter(id => id !== testId));
        }
    };
    const isButtonActive = selectedRows.length >= 2;
    const navigate = useNavigate();


    const resolveResultName = async (testName) => {

    }

    const handleMergeTests = async () => {
        setIsLoading(true)
        await axios.post(`https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF/merge?ids=[${selectedRows}]`, {})
            .then(response => {
                navigate(`/dashboard/results?id=${response?.data?.id}`)
                console.log('Response:', response.data);
                setIsLoading(false)
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false)

            });
    }
    const handleDeleteTest = async (data) => {
        setIsLoading(true)
        console.log(data)
        await axios.delete(`https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF/test/${data.id}`, {})
            .then(response => {
                fetchData()
                setIsLoading(false)
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false)

            });
    }
    const handleShareTest = (test) => {
        console.log(test)
        setSelectedTest(test)
        setShareTestModalOpen(true)
    };
    const handleSendTest = (test) => {
        console.log(test)
        setSelectedTest(test)
        setSendTestModalOpen(true)
    };
    const fetchData = async () => {
        setIsLoading(true)
        try {
            const response = await axios.get('https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF:v1/test'); // Replace with your actual API endpoint
            setTestData(response.data);
            setIsLoading(false)
        } catch (error) {
            console.error('Error fetching test data:', error);
            setIsLoading(false)
        }
    };
    useEffect(() => {
        fetchData();
        setShowTooltip(false);
    }, [isUserLoggedIn]);

    return (
        <div className="md:pl-32 flex flex-col flex-1 bg-[#080421] !min-h-screen">
            <div className="grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-12  text-white" >
                <div className="col-span-1 sm:col-span-8 md:col-span-8 lg:col-span-8">
                    <div className='grid grid-cols-12  max-w-7xl sm:px-6 md:px-8  mx-auto mt-12'>
                        <div className="col-span-6">
                            <h1 className="text-2xl font-semibold px-8 md:px-12 pt-0 md:pt-8">Dashboard</h1>
                        </div>
                        <div className='col-span-6 pt-0 md:pt-8 px-8'>
                            <a href="/test" className=" right-0 float-right hover:scale-105 mx-4 rounded-full bg-[#7035de] hover:bg-opacity-80 text-white py-2 px-4 text-xs font-bold md:py-3 md:px-8 md:text-md">Take Test</a>
                        </div>
                    </div>
                    <EditTestModal open={isEditTestModalOpen} setOpen={setEditTestModalOpen} test={selectedTest} updateTest={handleUpdateTest} />
                    <ShareTestModal withSend={false} withPublicLink={true} open={isShareTestModalOpen} setOpen={setShareTestModalOpen} test={selectedTest} updateTest={handleUpdateTest} />
                    <ShareTestModal withSend={true} withPublicLink={false} open={isSendTestModalOpen} setOpen={setSendTestModalOpen} test={selectedTest} updateTest={handleUpdateTest} />

                    <div className="max-w-7xl sm:px-6 md:px-8  mx-auto">
                        {/* Replace with your content */}

                        <div className="p-4">

                            <div className="p-4">
                                <div className="rounded-lg">

                                    <div className="sm:px-6 ">
                                        <div className="sm:flex sm:items-center mx-auto">
                                            <div className="sm:flex-auto">
                                                <p className='pt-2 text-sm'>To get the most accurate test result for yourself, have people who know you well take the test on your behalf, share their results with you, then select the results here and select " Merge" to get an averaged result of their opinions of you.</p>

                                            </div>
                                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                                <button
                                                    className={`${isButtonActive && !isLoading ? '' : 'bg-opacity-30 cus'}  inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto`}
                                                    disabled={!isButtonActive && isLoading}
                                                    onClick={handleMergeTests}>

                                                    Merge
                                                </button>

                                            </div>
                                        </div>
                                        <div className="sm:flex-auto mt-10">
                                            <h1 className="text-xl font-semibold text-white">My Tests</h1>
                                            <center className='w-full my-4'>
                                                {isLoading ?
                                                    <>
                                                        <Loading className="mx-auto self-center justify-center text-center items-center justify-self-center" />
                                                    </>
                                                    : <p className='h-5'></p>

                                                }
                                            </center>
                                        </div>


                                        <div className="mt-4 flex flex-col border border-[#493A65] bg-[#493A65] rounded-lg bg-opacity-20">
                                            <div className="-my-2 overflow-x-auto">
                                                <div className="inline-block min-w-full py-2 align-middle">
                                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                        <table className="min-w-full divide-y divide-gray-300">
                                                            <thead className="">
                                                                <tr>
                                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6">

                                                                    </th>
                                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6 w-1/5">
                                                                        Name
                                                                    </th>
                                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                                        Result
                                                                    </th>
                                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                                        Date
                                                                    </th>

                                                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                                        <span className="sr-only">Send</span>
                                                                    </th>
                                                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                                        <span className="sr-only">Edit</span>
                                                                    </th>
                                                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                                        <span className="sr-only">Share</span>
                                                                    </th>
                                                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                                        <span className="sr-only">Lock</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="">
                                                                {testData.tests.length > 0 ? (
                                                                    testData.tests.map((test, personIdx) => (
                                                                        <tr key={test.email} className={personIdx % 2 === 0 ? undefined : 'bg-[#493A65] bg-opacity-30'}>
                                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                <div className="flex items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="h-4 w-4 text-[#493A65] focus:ring-[#493A65] rounded"
                                                                                        onChange={(e) => handleCheckboxChange(test.id, e.target.checked)}
                                                                                        checked={selectedRows.includes(test.id)}
                                                                                    />
                                                                                </div>
                                                                            </td>

                                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                <Link to={`/dashboard/results?id=${test.id}`} className='text-blue-500'>
                                                                                    {test.name}
                                                                                </Link>
                                                                            </td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm w-lg text-white"><ResultText result={test.result} /></td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-white">{new Date(test.created_at).toLocaleString()}</td>


                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                                                                                <button onClick={() => handleSendTest(test)} className='rounded-md bg-[#7035de] hover:bg-opacity-80 col-span-4 flex justify-between gap-2 p-2 text-2xs'>Send<FaPaperPlane className='h-5 text-gray-500'></FaPaperPlane></button>
                                                                            </td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                                                                                <button onClick={() => handleEditTest(test)} className='rounded-md bg-[#7035de] hover:bg-opacity-80 col-span-4 flex justify-between gap-2 p-2 text-2xs'>Edit<FaPen className='h-5 text-gray-500'></FaPen></button>
                                                                            </td>

                                                                            <td className="whitespace-nowrap py-4 md:pl-3 md:pr-4 text-right text-sm font-medium sm:pr-6">


                                                                                <div className='col-span-4'>
                                                                                    <button onClick={() => handleShareTest(test)} data-tooltip-target="tooltip-hover" data-tooltip-trigger="click" className='col-span-1 rounded-md bg-[#7035de] hover:bg-opacity-80 flex justify-between gap-2 p-2 text-2xs'>Share <FaShare className='h-5 text-gray-500'></FaShare></button>
                                                                                    {(showTooltip && copiedTest === test?.share_id) && (
                                                                                        <div className="absolute bg-black text-white text-xs rounded py-1 px-2 right-0 bottom-full mb-2">
                                                                                            Copied Share Link!
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </td>

                                                                            <td className="text-sm text-white"> {test.is_paid ? "" : <div onClick={()=>handleLockClick(test)} className="text-xl lock-icon cursor-pointer">🔒</div>}</td>

                                                                        </tr>
                                                                    ))) : (
                                                                    <tr>
                                                                        <td colSpan="5" className="text-center py-4 text-sm text-white">
                                                                            No data available.
                                                                        </td>
                                                                    </tr>)}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-4">
                                <div className=" rounded-lg">
                                    <div className="sm:px-6 ">
                                        <div className="sm:flex sm:items-center mx-auto">
                                            <div className="sm:flex-auto">
                                                <h1 className="text-xl font-semibold text-white">Shared Tests</h1>

                                            </div>
                                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

                                            </div>
                                        </div>


                                        <div className="mt-8 flex flex-col border border-[#493A65] bg-[#493A65] rounded-lg bg-opacity-20">
                                            <div className="-my-2 overflow-x-auto">
                                                <div className="inline-block min-w-full py-2 align-middle ">
                                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                        <table className="min-w-full divide-y divide-gray-300">
                                                            <thead className="">
                                                                <tr>
                                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6">
                                                                    </th>

                                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6">
                                                                        Name
                                                                    </th>
                                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6">
                                                                        Shared From
                                                                    </th>
                                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                                        Result
                                                                    </th>
                                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                                        Date
                                                                    </th>

                                                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                                        <span className="sr-only">Edit</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="">
                                                                {testData.shared_tests.length > 0 ? (
                                                                    testData.shared_tests.map((test, personIdx) => (
                                                                        <tr key={test.email} className={personIdx % 2 === 0 ? undefined : 'bg-[#493A65] bg-opacity-30'}>
                                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                <div className="flex items-center">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="h-4 w-4 text-[#493A65] focus:ring-[#493A65] rounded"
                                                                                        onChange={(e) => handleCheckboxChange(test.id, e.target.checked)}
                                                                                        checked={selectedRows.includes(test.id)}
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                <Link to={`/dashboard/results?id=${test.id}`} className='text-blue-500'>
                                                                                    {test.name}
                                                                                </Link>
                                                                            </td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-white">{test?.taker?.email}</td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-white"><ResultText result={test.result}></ResultText></td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-white">{new Date(test.created_at).toLocaleString()}</td>
                                                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                                <button onClick={() => handleEditTest(test)} className='rounded-md bg-[#7035de] hover:bg-opacity-80 flex justify-between p-2 text-2xs gap-2'>Edit<FaPen className='h-5 text-gray-500'></FaPen></button>

                                                                            </td>
                                                                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-white"> {test.is_paid ? "" : <div onClick={()=>handleLockClick(test)}  className="text-xl lock-icon cursor-pointer">🔒</div>}</td> */}
                                                                        </tr>
                                                                    ))) : (
                                                                    <tr>
                                                                        <td colSpan="5" className="text-center py-4 text-sm text-white">
                                                                            No data available.
                                                                        </td>
                                                                    </tr>)}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-4">
                                <div className="rounded-lg">
                                    <div className="sm:px-6 ">
                                        <div className="sm:flex sm:items-center mx-auto">
                                            <div className="sm:flex-auto">
                                                <h1 className="text-xl font-semibold text-white">Merged Tests</h1>

                                            </div>
                                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

                                            </div>
                                        </div>


                                        <div className="mt-8 flex flex-col border border-[#493A65] bg-[#493A65] rounded-lg bg-opacity-20">
                                            <div className="-my-2 overflow-x-auto">
                                                <div className="inline-block min-w-full py-2 align-middle">
                                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                        <table className="min-w-full divide-y divide-gray-300">
                                                            <thead className="">
                                                                <tr>

                                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6">
                                                                        Name
                                                                    </th>
                                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                                        Result
                                                                    </th>
                                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                                        Date
                                                                    </th>

                                                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                                        <span className="sr-only">Edit</span>
                                                                    </th>
                                                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                                        <span className="sr-only">Lock</span>
                                                                    </th>
                                                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                                        <span className="sr-only">Lock</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="">
                                                                {testData.merged_tests.length > 0 ? (
                                                                    testData.merged_tests.map((test, personIdx) => (
                                                                        <tr key={test.email} className={personIdx % 2 === 0 ? undefined : 'bg-[#493A65] bg-opacity-30'}>
                                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                                <Link to={`/dashboard/results?id=${test.id}`} className='text-blue-500'>
                                                                                    {test.name}
                                                                                </Link>
                                                                            </td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-white"><ResultText result={test.result}></ResultText></td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-white">{new Date(test.created_at).toLocaleString()}</td>


                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                                                                                <button onClick={() => handleSendTest(test)} className='rounded-md bg-[#7035de] hover:bg-opacity-80 col-span-4 flex justify-between gap-2 p-2 text-2xs'>Send<FaPaperPlane className='h-5 text-gray-500'></FaPaperPlane></button>
                                                                            </td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                                                                                <button onClick={() => handleEditTest(test)} className='rounded-md bg-[#7035de] hover:bg-opacity-80 col-span-4 flex justify-between gap-2 p-2 text-2xs'>Edit<FaPen className='h-5 text-gray-500'></FaPen></button>
                                                                            </td>

                                                                            <td className="whitespace-nowrap py-4 md:pl-3 md:pr-4 text-right text-sm font-medium sm:pr-6">


                                                                                <div className='col-span-4'>
                                                                                    <button onClick={() => handleShareTest(test)} data-tooltip-target="tooltip-hover" data-tooltip-trigger="click" className='col-span-1 rounded-md bg-[#7035de] hover:bg-opacity-80 flex justify-between gap-2 p-2 text-2xs'>Share <FaShare className='h-5 text-gray-500'></FaShare></button>
                                                                                    {(showTooltip && copiedTest === test?.share_id) && (
                                                                                        <div className="absolute bg-black text-white text-xs rounded py-1 px-2 right-0 bottom-full mb-2">
                                                                                            Copied Share Link!
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                                                                                <button onClick={() => handleDeleteTest(test)} className='rounded-md bg-red-500 hover:bg-opacity-80 col-span-3 flex justify-between p-2 text-2xs gap-2'>Delete<XIcon className='h-5 text-white'></XIcon></button>
                                                                            </td>
                                                                            <td className="text-sm text-white"> {test.is_paid ? "" : <div onClick={()=>handleLockClick(test)} className="text-xl lock-icon cursor-pointer">🔒</div>}</td>

                                                                        </tr>
                                                                    ))) : (
                                                                    <tr>
                                                                        <td colSpan="5" className="text-center py-4 text-sm text-white">
                                                                            No data available.
                                                                        </td>
                                                                    </tr>)}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-span-1 md:col-span-4 xl:mt-20 mb-10 '>
                    <div className="block xl:fixed xl:px-0 xl:pr-12 text-white">
                        {/* <div className="bg-purple-800 bg-opacity-10 border border-[#7940CF] rounded-2xl p-10 shadow-lg">
                            <div className="flex items-center justify-between">
                                <div className="text-orange-400">Fe/Se</div>
                                <div>
                                    <div>25 followers</div>
                                    <div>14 following</div>
                                </div>
                            </div>
                            <div className="text-4xl font-bold my-4">12,256</div>
                            <button className="bg-purple-600 text-white rounded py-2 px-4">
                                share your profile
                            </button>
                        </div>

                        <div className="bg-purple-800 bg-opacity-10 border border-[#7940CF] mt-4 rounded-2xl p-10 shadow-lg">
                            <div className="text-lg font-bold">Quick Links</div>
                            <a href="#" className="flex items-center justify-between my-2">
                                Personality Types <span>→</span>
                            </a>
                            <a href="#" className="flex items-center justify-between my-2">
                                Retake the Test <span>→</span>
                            </a>
                            <a href="#" className="flex items-center justify-between my-2">
                                Upgrade your Profile <span>→</span>
                            </a>
                        </div> */}

                        <div className="bg-purple-800 bg-opacity-10 border border-[#7940CF] mt-4 rounded-2xl p-10 shadow-lg">
                            <center><img src="/promo-code.png" width={200} className="mb-5 animate-tilt"></img></center>
                            <center> <div className="text-lg font-bold">Full Results Promo Code</div> </center>
                            <p className="my-2 text-sm text-gray-500">
                                Want to buy a test for later, or send one as a gift to friends and family? Promo codes are single-use codes that never expire and can be redeemed to unlock a premium result. You can purchase a single code, or get one FREE when you buy two!
                            </p>
                            <div className="grid grid-cols-2 gap-4 mt-4">
                                <button onClick={() => window.open("https://buy.stripe.com/14kdTL0o4aTo4x2dQQ?client_reference_id=promo_single", '_blank')} className="bg-[#30D5C8] border border-[#30D5C8] text-[#000] delay-150 hover:transition hover:scale-105 ease-in-out rounded py-2 px-4 ">
                                    Buy One
                                </button>
                                <button onClick={() => window.open("https://buy.stripe.com/dR65nf6Ms7Hc1kQcMN?client_reference_id=promo_three", "_black")} className="bg-[#30D5C8] border border-[#30D5C8] text-[#000] delay-150 hover:transition hover:scale-105 ease-in-out rounded py-2 px-4 col-span-1">
                                    Buy 3-pack
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardHome;
