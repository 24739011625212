import React, { useState, useEffect } from 'react';
import Header from "../../components/Header";
import * as Style from '../Home/styles'
import AuthModal from "../../components/AuthModal";
import Footer from "../../components/Footer";
import axios from 'axios';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import { Helmet } from "react-helmet";
import TakeTestButton from '../../components/TakeTestButton';


function sortObjectById(obj) {
  const arr = Object.keys(obj).map(key => ({
    name: key,
    ...obj[key]
  }));

  const sortedArr = arr.sort((a, b) => a.id - b.id);

  const sortedObj = sortedArr.reduce((acc, current) => {
    acc[current.name] = {
      description: current.description,
      theme_color: current.theme_color,
      items: current.items,
      id: current.id
    };
    return acc;
  }, {});

  return sortedObj;
}

function hexToRGBA(hex, opacity) {
  let r = 0, g = 0, b = 0;
  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 digits
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

function sortPersonalityTypesById(data) {
  data.sort((a, b) => a.id - b.id);
  return data;
}
function transformData(arr, pTypes) {
  let result = {};
  arr.forEach(({ attributes }) => {
    const { name, description, theme_color, identifier } = attributes;
    const sortedItems = sortPersonalityTypesById(pTypes[name])
    result[name] = { description, theme_color, items: sortedItems, id: Number(identifier) };
    // result[name] = sortObjectById(result[name])
  });
  result = sortObjectById(result)
  console.log(result)
  return result;
}


function groupByPersonalityGroups(data) {
  const groupedData = {};

  data.forEach(item => {
    const groupName = item.attributes.personality_groups.data.attributes.name;
    if (!groupedData[groupName]) {
      groupedData[groupName] = [];
    }
    groupedData[groupName].push(item);
  });

  return groupedData;
}

function PersonalityTypesList() {
  const [personalityTypeGroupsRaw, setPersonalityTypeGroupsRaw] = useState([]);
  const [loading, setLoading] = useState(false);

  function replaceSpacesWithHyphens(str) {
    return str.replace(/\s/g, '-');
  }

  const fetchPersonalityTypes = async () => {
    setLoading(true)
    const token = "6b1875cbfb2e50bbe3805dbb0385a8b14e707bdbf2325674ddaad125239e7d3401f8aa2cca4094a3ffa38f6f790d510ada111a5f03626ebe6661ddbf95456582d5fa9795ee4c2958fb72a8cf3187897b219cd2551eddf83e2232de063f0a95c4ebad38bd5245e3a18e986a939db189a728793103eba8c2fe75128c921d7085d3";
    try {
      const responseTypes = await axios.get('https://cms.typescience.ai/api/personality-types?populate=*&pagination[pageSize]=32',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseGroups = await axios.get('https://cms.typescience.ai/api/personality-groups?pagination[pageSize]=32',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const groupedTypes = await groupByPersonalityGroups(responseTypes.data.data)
      const TransformedData = transformData(responseGroups.data.data, groupedTypes)
      console.log(TransformedData)
      setPersonalityTypeGroupsRaw(TransformedData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error fetching personality types:', error);
    }
  };
  useEffect(() => {
    fetchPersonalityTypes()
  }, []);

  return (
    <>

      <Helmet>
        <meta charSet="utf-8" />
        <title>TypeScience - Personality Types</title>
        <meta name="description" content="Personality Types and Orientation Categories" />
        <meta property="og:type" content="article" />
        <body className="root" />

      </Helmet>
      <div className="px-1 text-white ">
        <main className="relative text-xl font-bold bg-[#080421] text-white justify-center items-center m-auto mb-16 md:mb-24">
          <h4 className="text-center font-grifter text-4xl md:text-45xl mt-16 md:mt-16">Personality Types</h4>
          <img
            className="absolute animate-pulse fade-image-radial m-auto"
            srcSet="bright.svg"
          ></img>
          <div className='my-12'>
            <TakeTestButton></TakeTestButton>
          </div>
        </main>


        {loading ?
          <center className='mx-auto w-full my-60'><Loading></Loading></center>
          :
          <div className='flex justify-center items-center mt-8'>
            <div className="grid grid-cols-1 md:grid-cols-1 w-full gap-0 mx-6 md:rotate-0">
              {Object.entries(personalityTypeGroupsRaw).map(([groupName, groupDetails]) => (
                <div key={groupName}
                  style={{
                    // borderTopColor: hexToRGBA(groupDetails.theme_color, 0.3), // Setting top border color dynamically
                    // borderBottomColor: hexToRGBA(groupDetails.theme_color, 0.3), // Setting bottom border color dynamically
                    backgroundColor: hexToRGBA(groupDetails.theme_color, 0.1), // Use RGBA for background
                    // borderTopWidth: '1px', // Set top border width
                    // borderBottomWidth: '1px', // Set bottom border width
                    // borderStyle: 'solid', // Necessary to define the style of the border
                  }}
                  className={`transition leading-[150.45px] bg-opacity-40 rounded-none md:px-0 lg:px-0`}
                >
                  <h2 className='text-[2.5rem] sm:text-[3.5rem] md:text-[6rem] lg:text-[8rem] xl:text-[10rem] pt-16 pb-8 text-center bg-clip'
                    style={{
                      fontFamily: "'GrifterBold', sans-serif",
                      background: `linear-gradient(0deg, ${groupDetails.theme_color} 2.92%, #fff 101.04%)`,
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent'
                    }}
                  >{groupName}</h2>
                  {/* <p className=' text-center text-xl w-3/4 md:w-1/4 mx-auto mb-12'>{groupDetails.description}</p> */}
                  <img
                    className="hidden opacity-30 xl:flex absolute w-screen h-screen z-0 t-0 r-0 fade-image-radial"
                    srcSet="instruction_bright.svg"
                  ></img>
                  <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4 gap-x-1 md:gap-x-4 gap-y-4 px-2 md:px-12 py-12 md:-rotate-0' key={groupDetails.name}>
                    {groupDetails.items.map(item => (
                      <Link to={`${item.attributes.name}-${replaceSpacesWithHyphens(item.attributes.description)}/summary`} className="hover:scale-105 hover:-rotate-1 bg-slate-400 bg-opacity-10 rounded-md items-center transition ease-in-out delay-300 hover:cursor-pointer py-12 text-white ">
                        <img
                          loading="lazy"
                          src={item.attributes.image ? "https://cms.typescience.ai" + item.attributes?.image?.data?.attributes?.url :""}
                          className="mb-1 aspect-[1] mx-auto sm:max-w-xs"
                        />
                        <p className="text-center self-center text-4xl  break-words font-grifter"> {item.attributes.name}</p>
                        <div className='px-24'><hr></hr></div>
                        <div className="text-center self-center text-xl font-grifter mt-1">{item.attributes.description} </div>
                        <div className="relative mt-5 text-lg 2xl:text-mid md:text-lg font-light text-center xl:text-balance px-8 ">
                          {item.attributes.introduction}
                        </div>
                      </Link>

                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>}
      </div>
    </>
  );
}



const PersonalityTypes = () => {
  return (
    <Style.Div>
      <AuthModal />
      <Header active="personality-types"></Header>
      <PersonalityTypesList></PersonalityTypesList>
      <Footer></Footer>
    </Style.Div>
  );
}

export default PersonalityTypes;