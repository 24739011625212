import React, { useState, useEffect } from 'react';
import { useModal } from '../contexts/ModalContext.js';
import useAuth from '../hooks/useAuth';


function AuthModal() {

  const [activeTab, setActiveTab] = useState('login');
  const { isModalOpen, hideModal ,shareId } = useModal();
  const bgColorClass = activeTab === 'login' ? 'bg-indigo-500' : 'bg-transparent';
  const bgColorClassSignup = activeTab === 'signup' ? 'bg-indigo-500' : 'bg-transparent';
  const { login, signup, error, user, setError } = useAuth();

  // Additional states for managing animations
  const [showModal, setShowModal] = useState(false);
  const [animationClass, setAnimationClass] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [signup_email, setSignUpEmail] = useState('');
  const [signup_password, setSignUpPassword] = useState('');
  const [signup_confirm_password, setSignUpConfirmPassword] = useState('');
  const [signup_name, setSignUpName] = useState('');
  
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault()
    setLoading(true)
    let userData;
    if(typeof(shareId)!=="object"){
      userData = await login({
        email: email,
        password: password,
        share_ids:shareId
      });
    }
    else{
      userData = await login({
        email: email,
        password: password,
      });
    }
    
    if (userData) {
      hideModal()
      window.location.href="/dashboard";
      setError("")
    }
    setLoading(false)
  };

  const handleSignUp = async (e) => {
    e.preventDefault()
    setLoading(true)
    let userData;

    if(signup_password !== signup_confirm_password ){
      setError("Passwords Doesn't match")
      return
    }

    if(typeof(shareId)!=="object"){
      userData = await signup({
        name:signup_name,
        email: signup_email,
        password: signup_password,
        share_ids:shareId
      });
    }
    else{
      userData = await signup({
        name:signup_name,
        email: signup_email,
        password: signup_password,
      });
    }
    if (userData) {
      hideModal()
      window.location.href="/dashboard";
      setError("")
    }
    setLoading(false)
  };

  useEffect(() => {
    if (isModalOpen) {
      setShowModal(true);
      setAnimationClass('modal-enter');
      setTimeout(() => setAnimationClass('modal-enter-active'), 10); // Start animation shortly after the modal is set to show
    } else {
      setAnimationClass('modal-exit-active');
      setTimeout(() => setShowModal(false), 300); // Delay hiding the modal until the animation completes
    }
  }, [isModalOpen]);



  if (!showModal) return null;
  return (

    <div className={`fixed top-0 z-[999] inset-0 overflow-y-auto h-full w-full flex justify-center items-center ${animationClass}`}>
      <div id="login-popup" tabindex="-1"
        className="bg-black/75 overflow-y-auto overflow-x-hidden fixed bottom-0 right-0 left-0 h-full items-center justify-center flex">
        <div className="transition fade-in delay-500  relative p-4 w-full max-w-xl md:h-auto">
          <div className="relative bg-[#080421] rounded-lg shadow p-10">
            <button onClick={hideModal} type="button"
              className="z-[999] absolute top-3 right-2.5 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"><svg
                aria-hidden="true" className="w-5 h-5" fill="#c6c7c7" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  cliprule="evenodd"></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>

            <div className="p-5">

              <div className='mb-5 mt-10'>


                <div className="sm:block" >
                  <nav className="relative z-0 rounded-lg flex divide-x divide-gray-300" aria-label="Tabs">

                    <div onClick={() => setActiveTab('login')} className="cursor-pointer text-white rounded-l-lg group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-sm font-medium text-center focus:z-10" aria-current="page">
                      <span>Login</span>
                      {/* {typeof(shareId)!=="object"?<p>{shareId}</p>:""} */}
                      <span aria-hidden="true" className={`absolute inset-x-0 bottom-0 h-0.5 ${bgColorClass}`} >
                      </span>
                    </div>

                    <div onClick={() => setActiveTab('signup')} className="cursor-pointer text-white rounded-r-lg group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-sm font-medium text-center focus:z-10">
                      <span>Sign Up</span>
                      <span aria-hidden="true" className={`absolute inset-x-0 bottom-0 h-0.5 ${bgColorClassSignup}`} >
                      </span>

                    </div>
                  </nav>
                </div>
              </div>


              {
                activeTab === 'login' &&
                <>
                  <div className="text-center mb-10">
                    <p className="mb-3 text-2xl font-semibold leading-5 text-white">
                      Login to your account
                    </p>
                    <p className="mt-2 text-sm leading-4 text-white">
                      Not a member yet? Create a free profile by taking our personality test or entering your results yourself.
                    </p>
                  </div>
                  <form className="space-y-6" onSubmit={handleLogin}>
                    <div>
                      <label for="email" className="block text-sm font-medium text-white"> Email address </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autocomplete="email"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          style={{ backgroundColor: "#1A1C26", color: "#fff" }}
                          placeholder="Email"
                          value={email}
                          onFocus={(e) => setError("")}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div>
                      <label for="password" className="block text-sm font-medium text-white"> Password </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autocomplete="current-password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          style={{ backgroundColor: "#1A1C26", color: "#fff" }}
                          placeholder="Password"
                          value={password}
                          onFocus={(e) => setError("")}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        {/* <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" /> */}
                        {/* <label for="remember-me" className="ml-2 block text-sm text-white"> Remember me </label> */}
                      </div>

                      <div className="text-sm">
                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500"> Forgot your password? </a>
                      </div>
                    </div>
                    <div>


                      {error && <p className="text-center text-sm mb-2 text-red-500">{error}</p>}

                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        style={{ backgroundColor: "#7035DE" }}
                        disabled={loading}>
                        {loading ?
                          <div
                            className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status">
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
                          </div> :
                          'Login'}
                      </button>
                    </div>
                  </form>

                </>

              }

              {
                activeTab === 'signup' &&
                <>
                  <div className="text-center mb-10">
                    <p className="mb-3 text-2xl font-semibold leading-5 text-white">
                      Sign Up
                    </p>
                    <p className="mt-2 text-sm leading-4 text-white">
                      Not a member yet? Create a free profile by taking our personality test or entering your results yourself.
                    </p>
                  </div>
                  <form className="space-y-6" onSubmit={handleSignUp}>
                    <div>
                      <label for="email" className="block text-sm font-medium text-white"> Name </label>
                      <div className="mt-1">
                        <input
                          id="signup_name"
                          name="signup_name"
                          type="signup_name"
                          autocomplete="signup_name"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          style={{ backgroundColor: "#1A1C26", color: "#fff" }}
                          placeholder="Name"
                          value={signup_name}
                          onFocus={(e) => setError("")}
                          onChange={(e) => setSignUpName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <label for="email" className="block text-sm font-medium text-white"> Email address </label>
                      <div className="mt-1">
                        <input
                          id="signup_email"
                          name="signup_email"
                          type="signup_email"
                          autocomplete="signup_email"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          style={{ backgroundColor: "#1A1C26", color: "#fff" }}
                          placeholder="Email"
                          value={signup_email}
                          onFocus={(e) => setError("")}
                          onChange={(e) => setSignUpEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div>
                      <label for="password" className="block text-sm font-medium text-white"> Password </label>
                      <div className="mt-1">
                        <input
                          id="signup_password"
                          name="signup_password"
                          type="password"
                          autocomplete="current-password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          style={{ backgroundColor: "#1A1C26", color: "#fff" }}
                          placeholder="Password"
                          value={signup_password}
                          onFocus={(e) => setError("")}
                          onChange={(e) => setSignUpPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div>
                      <label for="password" className="block text-sm font-medium text-white">Confirm Password </label>
                      <div className="mt-1">
                        <input
                          id="signup_confirm_password"
                          name="signup_confirm_password"
                          type="password"
                          autocomplete="current-password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          style={{ backgroundColor: "#1A1C26", color: "#fff" }}
                          placeholder="Confirm Password"
                          value={signup_confirm_password}
                          onFocus={(e) => setError("")}
                          onChange={(e) => setSignUpConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        {/* <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" /> */}
                        {/* <label for="remember-me" className="ml-2 block text-sm text-white"> Remember me </label> */}
                      </div>

                      <div className="text-sm">
                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500"> Forgot your password? </a>
                      </div>
                    </div>
                    <div>


                      {error && <p className="text-center text-sm mb-2 text-red-500">{error}</p>}

                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        style={{ backgroundColor: "#7035DE" }}
                        disabled={loading}>
                        {loading ?
                          <div
                            className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status">
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
                          </div> :
                          'Sign Up'}
                      </button>
                    </div>
                  </form>
                </>
              }



            </div>
          </div>
        </div>
      </div>

    </div>
  );
}




export default AuthModal;

