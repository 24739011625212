import React, { useState, useEffect } from 'react';
import Header from "../../components/Header";
import * as Style from '../Home/styles'
import AuthModal from "../../components/AuthModal";
import Footer from "../../components/Footer";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FaRocket } from 'react-icons/fa'; // This is assuming you're using react-icons for icons
import ReactMarkdown from 'react-markdown';
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import { space } from 'postcss/lib/list';
import { useModal } from '../../contexts/ModalContext';
import useAuth from '../../hooks/useAuth';
import { Route, Routes, NavLink } from 'react-router-dom';
import DOMPurify from 'dompurify';
import '../Blogs/Blogs.css';


/////Custom
// Custom component for blockquotes
const CustomBlockquote = ({ children }) => (
  <blockquote className='my-10' style={{ fontStyle: 'italic', borderLeft: '4px solid #ccc', color:"#ccc", paddingLeft: '1em' }}>
    {children}
  </blockquote>
);

// Custom component for h1
const CustomH1 = ({ children }) => (
  <h1  className="font-grifter" style={{ fontSize: '2em', margin: '1em 0' }}>
    {children}
  </h1>
);

// Custom component for h2
const CustomH2 = ({ children }) => (
  <h2 className="font-grifter" style={{ fontSize: '1.5em',  margin: '0.75em 0' }}>
    {children}
  </h2>
);

// Custom component for paragraphs
const CustomParagraph = ({ children }) => (
  <p style={{ lineHeight: '1.6', margin: '0.5em 0' }}>
    {children}
  </p>
);

// Custom component for lists
const CustomList = ({ children }) => (
  <ul style={{ paddingLeft: '1em', listStyleType: 'disc' }}>
    {children}
  </ul>
);

// Custom component for list items
const CustomListItem = ({ children }) => (
  <li style={{ margin: '0.5em 0' }}>
    {children}
  </li>
);

// Custom component for emphasized text
const CustomEmphasis = ({ children }) => (
  <em style={{ fontStyle: 'italic' }}>
    {children}
  </em>
);

// Custom component for strong text
const CustomStrong = ({ children }) => (
  <strong style={{ fontWeight: 'bold' }}>
    {children}
  </strong>
);

////End of Custom


const imgCustom = (imageData) => {
  const size = imageData.alt?.match(/\{(\d+)x(\d+)\}/)  // Regex to look for sizing pattern  

  return (
    <img
      src={imageData.src}
      loading="lazy"
      className='mx-auto my-4 w-64 h-64'
    />
  )
}

const CustomUl = ({ children, ...props }) => (
  <ul className="list-disc pl-5 space-y-2" {...props}>{children}</ul>
);

const CustomOl = ({ children, ...props }) => (
  <ol className="list-disc pl-5 space-y-2" {...props}>{children}</ol>
);

const CustomLi = ({ children, ...props }) => (
  <li className="text-lg text-white" {...props}>{children}</li>
);

const pCustom = ({ children, ...props }) => {
  return <p className="my-4" {...props}>{children}</p>
}

const liCustom = (liData) => {
  console.log(liData)
  return (
    <li>{liData}</li>
  )
}

const Introduction = (data) => {
  const sanitizedContent = DOMPurify.sanitize(data?.data?.resultDetail?.data[0]?.attributes?.summary_ht);

  return (
    <section id="introduction" className="mb-0 text-white pt-10">
      <h1 className="text-4xl font-bold mb-6 font-grifter">Overview</h1>
      <p className="text-lg">
        <ReactMarkdown components={{ img: imgCustom, ul: CustomUl, li: CustomLi }} children={data?.data?.resultDetail?.data[0]?.attributes?.introduction_ht}></ReactMarkdown>
      </p>
      <div id="strength" className='pt-8'>
        <Section title={`Strengths`}>
          <ReactMarkdown components={{ img: imgCustom, ul: CustomUl, li: CustomLi }}>{data?.data?.resultDetail?.data[0]?.attributes?.strengths_ht}</ReactMarkdown>
        </Section>
      </div>

      <div id="weaknesses" className='pt-8'>
        <Section title={`Weaknesses`}>
          <ReactMarkdown components={{ img: imgCustom, ul: CustomUl, li: CustomLi }}>{data?.data?.resultDetail?.data[0]?.attributes?.weaknesses_ht}</ReactMarkdown>
        </Section>
      </div>
    </section>
  );
};

const CoreEssence = (data) => {
  return (
    <section id="core-essence" className="mb-0 text-white pt-10">
      {/* <h2 className="text-3xl font-bold mb-6 font-grifter">Core Essence</h2> */}
      <p className="text-lg text-balance">
        <ReactMarkdown components={{ img: imgCustom }}>{data?.data?.resultDetail?.data[0]?.attributes?.core_essence_ht}</ReactMarkdown>
      </p>
      {/* <img src='/ps.png' className='py-4'></img> */}
    </section>
  );
};

const LifeAdvice = (data) => {
  let segments = location.pathname.split('/').filter(Boolean);
  let functionName = extractName(segments[1])
  function extractName(str) {
    const name = str.split('-')[0];
    return name;
  }
  return (
    <>

      <div id="relateable" className='pt-8'>
        <Section title={`Relatable Scenarios for the ${functionName}s`} >
          <ReactMarkdown components={{ img: imgCustom, ol: CustomOl, p: pCustom, ul: CustomUl, li: CustomLi }} className={'text-lg'}>{data?.data?.resultDetail?.data[0]?.attributes?.relateable_scenarios_ht}</ReactMarkdown>
        </Section>
      </div>
      <section id="life-advice" className='text-white pt-10'>
        <h2 className="text-3xl font-bold mb-6 font-grifter">Life Advice</h2>
        <div className="text-lg">
          <ReactMarkdown components={{ img: imgCustom, ul: CustomUl, li: CustomLi }} children={data?.data?.resultDetail?.data[0]?.attributes?.life_advice_ht}></ReactMarkdown>
        </div>
      </section>
    </>
  );
};

const Section = ({ title, children }) => {

  return (
    <section className="">
      <h2 className="text-3xl font-bold mb-6 font-grifter">{title}</h2>
      <div className="space-y-2 text-sm">{children}</div>
    </section>
  );
};
const StrengthWeakness = (data) => {
  let segments = location.pathname.split('/').filter(Boolean);
  let functionName = extractName(segments[1])


  function extractName(str) {
    const name = str.split('-')[0];
    return name;
  }

  return (
    <div className=" text-white mb-4">
      <div className="max-w-4xl ">

        <div id="summary">
          <ReactMarkdown components={{ 
            img: imgCustom, 
            ul: CustomUl, 
            li: CustomLi,
            blockquote: CustomBlockquote,
            h1: CustomH1,
            h2: CustomH2,
            p: CustomParagraph,
            ul: CustomList,
            li: CustomListItem,
            em: CustomEmphasis,
            strong: CustomStrong, 
            }}>{data?.data?.data[0]?.attributes?.summary_ht}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};



const CareerPaths = (data) => {
  return (
    <section id="career_paths_ht" className="mb-0 text-white pt-5">
      {/* <h2 className="text-3xl font-bold mb-6 font-grifter">Core Essence</h2> */}
      <p className="text-lg text-balance">
        <ReactMarkdown components={{ 
          img: imgCustom,
          blockquote: CustomBlockquote,
          h1: CustomH1,
          h2: CustomH2,
          p: CustomParagraph,
          ul: CustomList,
          li: CustomListItem,
          em: CustomEmphasis,
          strong: CustomStrong,
          }}>{data?.data?.data[0]?.attributes?.career_paths_ht}</ReactMarkdown>
      </p>
      {/* <img src='/ps.png' className='py-4'></img> */}
    </section>
  );
};


const RomanticRelationship = (data) => {
  return (
    <section id="romantic_relationship_ht" className="mb-0 text-white pt-5">
      {/* <h2 className="text-3xl font-bold mb-6 font-grifter">Core Essence</h2> */}
      <p className="text-lg text-balance">
        <ReactMarkdown components={{ 
          img: imgCustom,
          blockquote: CustomBlockquote,
          h1: CustomH1,
          h2: CustomH2,
          p: CustomParagraph,
          ul: CustomList,
          li: CustomListItem,
          em: CustomEmphasis,
          strong: CustomStrong,
           }}>{data?.data?.data[0]?.attributes?.romantic_relationship_ht}</ReactMarkdown>
      </p>
      {/* <img src='/ps.png' className='py-4'></img> */}
    </section>
  );
};
const Friendships = (data) => {
  return (
    <section id="friendships_ht" className="mb-0 text-white pt-5">
      {/* <h2 className="text-3xl font-bold mb-6 font-grifter">Core Essence</h2> */}
      <p className="text-lg text-balance">
        <ReactMarkdown components={{ 
          img: imgCustom,
          blockquote: CustomBlockquote,
          h1: CustomH1,
          h2: CustomH2,
          p: CustomParagraph,
          ul: CustomList,
          li: CustomListItem,
          em: CustomEmphasis,
          strong: CustomStrong,}}>{data?.data?.data[0]?.attributes?.friendships_ht}</ReactMarkdown>
      </p>
      {/* <img src='/ps.png' className='py-4'></img> */}
    </section>
  );
};
const WorkplaceHabits = (data) => {
  return (
    <section id="workplace_habits_ht" className="mb-0 text-white pt-10">
      {/* <h2 className="text-3xl font-bold mb-6 font-grifter">Core Essence</h2> */}
      <p className="text-lg text-balance">
        <ReactMarkdown components={{ img: imgCustom }}>{data?.data?.data[0]?.attributes?.workplace_habits_ht}</ReactMarkdown>
      </p>
      {/* <img src='/ps.png' className='py-4'></img> */}
    </section>
  );
};

const Sidebar = ({ isFixed, segment, active, setSideBarActiveState }) => {

  const navItems = [
    { id: 1, text: 'Summary', active: "summary", route: "summary" },
    { id: 2, text: 'Strengths and Weaknesses', active: "strengths-and-weaknesses", route: "strengths-and-weaknesses" },
    { id: 3, text: 'Workplace & Career', active: "career-paths", route: "career-paths" },
    { id: 4, text: 'Romantic Relationships', active: "romantic-relationships", route: "romantic-relationships" },
    { id: 5, text: 'Friendships', active: "friendships", route: "friendships" },
  ];

  const NavigatorLink = ({ to, children, highlight, setActiveTo, activeTo }) => {
    const baseClasses = "block text-mid hover:text-[#A588FF] font-grifter py-4 px-2";
    const highlightClass = highlight ? "text-[#A588FF] bg-white rounded-md py-4 px-2" : "";
    const classes = `${baseClasses} ${highlightClass}`;

    return (
      <Link to={to} className={classes} onClick={() => setActiveTo(activeTo)}>
        {children}
      </Link>
    );
  };
  return (
    <aside className="h-screen mx-auto text-white mt-10">
      <ul className={`space-y-0 transition delay-100  ${isFixed ? 'relative' : 'fixed top-20 md:max-w-32 lg:max-w-40 xl:max-w-52 2xl:max-w-56'} `}>
        <h3 className='font-grifter my-10 text-2xl font-bold'>Explore This Type</h3>
        {navItems.map(item => (
          <NavigatorLink to={`${segment}/${item.route}`} highlight={active === item.active} setActiveTo={setSideBarActiveState} activeTo={item.active}>{item.text}</NavigatorLink>
        ))}

        {/* <Link to={`${segment}/summary`} className="block  hover:text-white font-grifter">Summary</Link>
        <Link to={`${segment}/strengths-and-weaknesses`} className="block  hover:text-white font-grifter">Strengths and Weaknesses</Link>
        <Link to={`${segment}/career-paths`} className="block  hover:text-white font-grifter">Career Paths</Link>
        <Link to={`${segment}/romantic-relationships`} className="block  hover:text-white font-grifter">Romantic Relationships</Link>
        <Link to={`${segment}/friendships`} className="block  hover:text-white font-grifter">Friendships</Link>
        <Link to={`${segment}/workplace-habits`} className="block  hover:text-white font-grifter">Workplace Habits</Link> */}
      </ul>
    </aside>
  );
};
const SidebarRight = () => {
  const { showModal } = useModal();
  const { isUserLoggedIn, logout, user } = useAuth();
  const handleLoginButtonClick = () => {
    showModal();
  };
  return (
    <aside className="mx-4 end-0 text-white mt-10 transition ease-in-out delay-150">
      <div className="flex items-center justify-center p-4 bg-gray-900 rounded-lg">
        <div className="text-center">
          {isUserLoggedIn ?
            <h1 className="text-sm font-semibold text-white mb-6 "></h1>
            :
            <h1 className="text-sm font-semibold text-white mb-6 ">Want to know your personality ?</h1>
          }
          <Link to="/test" className=" inline-block bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-white py-2 px-8 text-sm rounded-full">
            Take the Test
          </Link>
          <p className="text-gray-400 text-sm text-white mt-8">
            Already Have Your Result ?{' '}

            {isUserLoggedIn ?
              <Link to="/dashboard" className="text-pink-400 hover:text-pink-500">Go to dashboard.</Link>
              :
              <button onClick={handleLoginButtonClick} className="text-pink-400 hover:text-pink-500">Log in</button>
            }
          </p>
        </div>
      </div>
    </aside>
  );
};

const MainContent = (resultDetail, isFixed) => {
  // Main content component that contains the right side elements

  return (

    <div className="mx-auto w-4/5 md:w-full">
      <Introduction data={resultDetail} />
      <GalleryCarousel resultDetail={resultDetail} />
      <LifeAdvice data={resultDetail} />
      {/* <PremiumSuiteBanner /> */}
      <CarouselComponent data={resultDetail} />
    </div>
  );
};


const PremiumSuiteBanner = () => {
  return (
    <div className="border-[#493A65] bg-[#493A65] bg-opacity-40 rounded-lg p-4 text-white flex flex-col space-y-2 mb-8">
      <h2 className="text-2xl text-left font-bold md:text-4xl">Get More With Premium Suite</h2>
      <p className="text-left text-md md:text-lg">Instantly Unlock The Remaining 95% Of INTP (Logician) Insights And Bring Your Best Ideas To Life.</p>
      <button className="mt-4 bg-purple-500 hover:bg-purple-600 w-fit text-white font-bold py-2 px-4 rounded inline-flex items-center">
        <FaRocket className="mr-2" />
        UPGRADE NOW
      </button>

    </div>
  );
};


const CarouselComponent = (data) => {
  let segments = location.pathname.split('/').filter(Boolean);
  let functionName = extractName(segments[1])


  function extractName(str) {
    const name = str.split('-')[0];
    return name;
  }
  return (
    <div className=" text-white">
      <div className="text-center mb-12 mt-32">
        <h2 className="text-4xl font-bold ">{functionName}s You May Know</h2>
      </div>

      <div className="">
        <div className="">

          <FamousGalleryCarousel resultDetail={data}></FamousGalleryCarousel>
          {/* {data?.data?.resultDetail?.data[0]?.attributes?.famous_peoples_image?.data?.map((item) => (
            <div key={item} className="">
              <img
                src={"https://cms.typescience.ai" + item?.attributes?.url}
                alt="Genius Avatar"
                className="w-32 h-32 bg-gray-800 rounded-md hover:scale-105 transition delay-150 ease-in-out cursor-pointer"
              />
              <p className="text-center text-xs m-2 text-gray-500">{item?.attributes?.caption}</p>
            </div>
          ))} */}
        </div>


      </div>


    </div>
  );
};


const GalleryCarousel = ({ resultDetail }) => {
  const [currentSliderIndex, setCurrentSliderIndex] = useState(0);

  const settings = {
    className: "",
    infinite: true,
    centerPadding: "100px",
    slidesToShow: 3,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: true,
    dots: false,
    beforeChange: (current, next) => setCurrentSliderIndex(next),
  };
  return (
    <div className='my-10'>
      <div className="slider-container">
        <Slider {...settings}>
          {
            resultDetail.resultDetail?.data[0]?.attributes?.galleries?.data?.map((item, index, array) => {
              const middleIndex = Math.floor(array.length / 2);
              const imageClass = index === middleIndex ? 'h-64 w-64 rounded-lg' : 'h-48 w-48 my-auto mx-16 hover:scale-105 transition delay-150 cursor-pointer  rounded-lg';
              return (
                <div
                  className={index === currentSliderIndex + 1 ? 'hover:scale-105 transition delay-150 cursor-pointer rounded-lg' : '' + "p-2 hover:scale-105 transition delay-150 cursor-pointer rounded-lg"}
                >
                  <figure className="relative max-w-sm transition-all duration-300 cursor-pointer filter  ">
                    <div>
                      <img
                        loading="lazy"
                        className='rounded-lg fade-image-bottom '
                        src={"https://cms.typescience.ai" + item?.attributes?.url}
                        alt=""
                        key={item?.id} // Assuming each item has a unique 'id' for React keys
                      />
                    </div>
                    <figcaption className="absolute px-4 text-3xs text-balance text-center text-white bottom-0 w-full rounded-b-lg bg-opacity-90 bg-black">
                      <p>{item?.attributes?.caption}</p>
                    </figcaption>
                  </figure>

                </div>
              );
            })
          }
        </Slider>
      </div>
    </div>
  )

}


const FamousGalleryCarousel = ({ resultDetail }) => {
  const [currentSliderIndex, setCurrentSliderIndex] = useState(0);

  const settings = {
    className: "",
    infinite: false,
    centerPadding: "100px",
    slidesToShow: 4,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: true,
    dots: false,
    beforeChange: (current, next) => setCurrentSliderIndex(next),
  };
  return (
    <div className='mb-24'>
      <div className="slider-container text-center">
        <Slider {...settings}>
          {
            resultDetail?.data?.resultDetail?.data[0]?.attributes?.famous_peoples_image?.data?.map((item, index, array) => {
              const middleIndex = Math.floor(array.length / 2);
              const imageClass = index === middleIndex ? 'h-64 w-64 rounded-lg' : 'h-48 w-48 my-auto mx-16 hover:scale-105 transition delay-150 cursor-pointer  rounded-lg';
              const captionParts = item?.attributes?.caption?.split(" - ");
              const name = captionParts?.[0]; // Name is the first part
              const famous_for = captionParts?.[1]; // Occupation is the second part
              return (
                <div
                  className={index === currentSliderIndex + 1 ? 'hover:scale-105 transition delay-150 cursor-pointer rounded-lg' : '' + "p-2 hover:scale-105 transition delay-150 cursor-pointer rounded-lg"}
                >
                  <figure className="relative max-w-sm transition-all duration-300 cursor-pointer filter px-5 ">
                    <div>
                      <img
                        loading="lazy"
                        className='rounded-lg fade-image-botto '
                        src={"https://cms.typescience.ai" + item?.attributes?.url}
                        alt=""
                        key={item?.id} // Assuming each item has a unique 'id' for React keys
                      />
                    </div>
                  </figure>
                  <p className="text-center text-xl font-grifter text-white text-opacity-70 mt-10">{name}</p>
                  <p className="text-center text-sm text-white text-opacity-70 ">{famous_for}</p>

                </div>
              );
            })
          }
        </Slider>
      </div>
    </div>
  )

}

const HorizontalNavBar = ({ isFixed, segment, active, setSideBarActiveState }) => {
  const navItems = [
    { id: 1, text: 'Summary', active: "summary", route: "summary" },
    { id: 2, text: 'Strengths and Weaknesses', active: "strengths-and-weaknesses", route: "strengths-and-weaknesses" },
    { id: 3, text: 'Workplace & Career', active: "career-paths", route: "career-paths" },
    { id: 4, text: 'Romantic Relationships', active: "romantic-relationships", route: "romantic-relationships" },
    { id: 5, text: 'Friendships', active: "friendships", route: "friendships" },
  ];

  const MobileNavLink = ({ to, children, highlight, setActiveTo, activeTo }) => {
    const baseClasses = "text-xs hover:text-[#A588FF] font-grifter text-center py-4 px-2";
    const highlightClass = highlight ? "text-[#A588FF] bg-white rounded-md py-4 px-2" : "";
    const classes = `${baseClasses} ${highlightClass}`;

    return (
      <Link to={to} className={classes} onClick={() => setActiveTo(activeTo)}>
        {children}
      </Link>
    );
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-2 ">
        <div className="flex items-center justify-between h-16 overflow-x-auto overflow-y-clip">
          <div className="flex items-center">
            <div className="flex space-x-4 align-middle items-center">
              {navItems.map(item => (
                <MobileNavLink to={`${segment}/${item.route}`} highlight={active === item.active} setActiveTo={setSideBarActiveState} activeTo={item.active}>{item.text}</MobileNavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};



const PersonalityTypes = () => {
  let segments = location.pathname.split('/').filter(Boolean);
  let functionName = extractName(segments[1])
  const [isSidebarFixed, setIsSidebarFixed] = useState(true);
  const [resultDetail, setResultDetail] = useState(null);
  const [sideBarActiveState, setSideBarActiveState] = useState("summary");


  function extractName(str) {
    const name = str.split('-')[0];
    return name;
  }
  useEffect(() => {

    const fetchPersonalityTypeDetails = async () => {

      const token = "6b1875cbfb2e50bbe3805dbb0385a8b14e707bdbf2325674ddaad125239e7d3401f8aa2cca4094a3ffa38f6f790d510ada111a5f03626ebe6661ddbf95456582d5fa9795ee4c2958fb72a8cf3187897b219cd2551eddf83e2232de063f0a95c4ebad38bd5245e3a18e986a939db189a728793103eba8c2fe75128c921d7085d3";
      try {
        const response = await axios.get(`https://cms.typescience.ai/api/personality-types?filters[name][$eq]=${functionName}&populate=*`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setResultDetail(response.data)
      } catch (error) {
        console.error('Error fetching personality types:', error);
      }
    };
    fetchPersonalityTypeDetails();
    const handleScroll = () => {
      if (window.scrollY > 720 && window.scrollY < 1250) {
        setIsSidebarFixed(false);
      } else {
        setIsSidebarFixed(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);





  return (
    <Style.Div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TypeScience - {segments[1]}</title>
        <meta name="description" content={resultDetail?.data?.resultDetail?.data[0]?.attributes?.introduction} />
        <meta property="og:type" content="article" />
        <body className="root" />

      </Helmet>
      <AuthModal />
      <Header active="personality-types"></Header>
      <div className='grid grid-cols-3 md:grid-cols-12 mx-auto my-10 md:my-0'>
        <div className='col-span-12 md:col-span-12  '>
          <img className='aspect-[2.5] rounded-xl w-4/5 mx-auto border-[#fff] border-24' src={"https://cms.typescience.ai" + resultDetail?.data[0]?.attributes?.banner?.data?.attributes?.url} />
        </div>
        {/* <div className='text-white col-span-1 md:col-span-3 align-middle text-end content-center'>
          <h4 className='text-4xl font-grifter'>{resultDetail?.data[0]?.attributes?.description}</h4>
          <h4 className='text-lg font-grifter' >{functionName}</h4>
          <h4 className='text-lg font-grifter'>Personality</h4>
        </div>
        <div className='hidden md:block col-span-1 align-middle text-end content-center'>
          <center><img className='float-right' src='/baz.png'></img></center>
        </div> */}
      </div>

      <div className='mt-8 md:hidden'>
        <HorizontalNavBar isFixed={isSidebarFixed} segment={segments[1]} active={sideBarActiveState} setSideBarActiveState={setSideBarActiveState}></HorizontalNavBar>
      </div>
      <main className='md:w-4/5 grid grid-cols-12 md:grid-cols-12 mx-auto mt-8 mb-16 w-full text-white'>

        <div className='hidden md:block col-span-1 md:col-span-2'>
          <Sidebar isFixed={isSidebarFixed} segment={segments[1]} active={sideBarActiveState} setSideBarActiveState={setSideBarActiveState}></Sidebar>
        </div>

        <div className='col-span-12 md:col-span-8 px-8'>
          <Routes>
            <Route path={`/${segments[1]}/summary`} element={<MainContent resultDetail={resultDetail} />} />
            <Route path={`/${segments[1]}/strengths-and-weaknesses`} element={<StrengthWeakness data={resultDetail} />} />
            <Route path={`/${segments[1]}/career-paths`} element={<CareerPaths data={resultDetail} />} />
            <Route path={`/${segments[1]}/romantic-relationships`} element={<RomanticRelationship data={resultDetail} />} />
            <Route path={`/${segments[1]}/friendships`} element={<Friendships data={resultDetail} />} />
            <Route path={`/${segments[1]}/workplace-habits`} element={<WorkplaceHabits data={resultDetail} />} />
          </Routes>
        </div>
        <div className='hidden md:block col-span-1 md:col-span-2'>
          <SidebarRight isFixed={isSidebarFixed}></SidebarRight>
        </div>
      </main>
      <Footer></Footer>
    </Style.Div>
  );
}

export default PersonalityTypes;
